import React, { useRef,useEffect, useState, useMemo } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/header";
import Footer from "../components/footer";
import SocPartners from "../components/socialPartners"
import ReactTextRotator from "react-text-rotator";

const useHover = () => {
  const [hovered, setHovered] = useState();
  
  const eventHandlers = useMemo(() => ({
    onMouseOver() { setHovered(true); },
    onMouseOut() { setHovered(false); }
  }), []);
  
  return [hovered, eventHandlers];
}

const IndexPage = () => {
  const [hovered, eventHandlers] = useHover();
  
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  const content = [
    {
      text: "Buying a used vehicle?",
      className: "rotator",
      animation: "zoom",
    },
    {
      text: "Taking a long drive?",
      className: "rotator",
      animation: "zoom",
    },
    {
      text: "Getting ready to take your vehicle to the shop?",
      className: "rotator",
      animation: "zoom",
    }
  ];

  return (
    <Layout classmain="homepage">
      <Seo
        title='Welcome to Autolytics'
        metaDesciption=''
      />
      <div className='homepage__hero-container container'>
        <div className='homepage__hero row'>
          <div className='intro col-md-5 col-12'>
            <div className='intro__inner'>
              <a
                className="homepage__hero-logo"
                href="/"
              >
                <img src="images/logo_short.svg" alt="Autolytics"/>

              </a>
              <h1 className='intro__inner--title'>
                  Powered by Data. Driven to Optimize.
              </h1>
              {/*<p className='intro__inner--p'>Powered by Data, Driven to Optimize.</p>*/}
              <a href="/contact" className='btn'>Contact Us</a>
            </div>
          </div>
          <div className={hovered? 'home hovered col-md-7 col-12' : 'home col-md-7 col-12'} onClick={()=> window.open("#services", "_self")}>
            <div {...eventHandlers} className="home__item home__item--v1">
              <h2 className='home__item--title'>Automotive</h2>
              <div className="home__inner--container">
                <div className="home__inner">
                  <h2>Automotive</h2>
                  <img className="services_icon" src="/images/car.svg"/>
                </div>
              </div>
            </div>
            <div {...eventHandlers} className="home__item home__item--v2">
              <h2 className='home__item--title'>Jobs</h2>
              <div className="home__inner--container">
                <div className="home__inner">
                  <h2>Jobs</h2>
                  <img className="services_icon" src="/images/work.svg"/>
                </div>
              </div>
            </div>
            <div {...eventHandlers} className="home__item home__item--v3">
              <h2 className='home__item--title'>Apartments</h2>
              <div className="home__inner--container">
                <div className="home__inner">
                  <h2>Apartments</h2>
                  <img className="services_icon" src="/images/ap.svg"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='services-out' id="services">
        <div className='services container'>
          <h2 className='services__title'>Our Platform</h2>
          <div className='services__inner row'>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <h3>Powered by Data</h3>
                <p>Our targeting technology leverages the power of big data to serve the right listings to the right customers at key moments in their search.</p>
              </div>
            </div>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <h3>Adaptive Targeting</h3>
                <p>Our adaptive targeting continuously updates customer preferences as their search evolves and reengages them with relevant listings.</p>
              </div>
            </div>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <h3>Massive Reach</h3>
                <p>Our platform operates on the largest display and social networks in the word, ensuring a near endless supply of customer inquires.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className='services-out' id="services">
        <div className='services container'>
          <div className='services__inner'>
           <img class="services-title cycle" src="/images/cycle.svg"/>
           <h2>Our Technology</h2>
           <p>Our proprietary fingerprinting technology anonymously builds shopper profiles across hundreds of automotive listing and research portals.  Our ad engine displays the right vehicles to the right shoppers at a fraction of the cost of other ad platforms.</p>
          </div>
        </div>
      </div>
      <div className=' ph ph-out ph-out--gray'>
        <div className='ph-container container'>
          <div className='ph-row row'>
            <div className='col-md-6 col-12 fright'>
              <div className='fixin auto1'></div>
            </div>
            <div className='col-md-6 col-12 fcenter'>
              <div className='ph-in'>
                <h3>Dealers Groups and Automotive Advertisers</h3>
                <p>Experience frictionless inventory uploads and complete bidding flexibility.  There is no fee to list inventory, and you can bid dynamically for the online traffic by publisher source and VIN. You can easily manage bids according to our rule engine, or manage to a target cost per action, including lead submission.  You are in complete control.</p>
              </div>
            </div>
          </div>
        </div>
    </div>
      <div className=' ph ph-out'>
        <div className='ph-container container'>
          <div className='ph-row row'>
            <div className='col-md-6 col-12 fright fcenter fc'>
              <div className='ph-in'>
                <h3>Automotive Publishers</h3>
                <p>Our technology offers the highest ad eCPM yield in the industry. With flexible integrations, including javascript and API, our sponsored listings render natively to your site. Our portal offers real-time revenue reporting.</p>
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <div className='fixin auto2'></div>
            </div>
          </div>
        </div>
      </div>*/}
      <div className='contact-out'>
      <div className='contact' id="contact">
        <div className='contact__container container'>
          <div className='contact__row row'>
            <div className='contact__col col-md-12 col-12'>
                <h3 className='contact__title'>Contact us</h3>
                <p class="pf">4518 W Swann Avenue, Tampa, FL 33609</p>
                <a target="_blank" href="mailto:contact@autolytics.io">contact@autolytics.io</a>
                <a target="_blank" href="tel:9178602530">917.860.2530</a>
                <a class="fb" target="_blank" href="https://www.facebook.com/Autolytics-109896801834832"><img src="/images/fb.svg" /></a>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer/>
    </Layout>
  )
}

export default IndexPage
